import React, { useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { getToken } from '../../../apiCommandsExecutor/token';
import { generateClientSyncId } from '../../../apiCommandsExecutor';
import { useStores } from '../../../hooks';
import TelephonyStoreContext from '../../../modules/maintainer/pages/Telephony/store/context';
import { IncomingCallSnackbar } from '../../../modules/maintainer/pages/Telephony/Snackbar/IncomingCallSnackbar';

const Websockets = () => {
    const { authStore, generalStore } = useStores();
    const orgBranchId = generalStore.currentOrganizationBranch?.id;
    const token = getToken();
    const telephonyStore = useContext(TelephonyStoreContext);

    const socketRef = useRef<any>(null);
    const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const currentTokenRef = useRef(token);
    const failedTokenRef = useRef<string | null>(null);

    const closeWebSocket = () => {
        if (socketRef.current) {
            socketRef.current.close();
            socketRef.current = null;
        }
    };

    const connectWebSocket = () => {
        if (!authStore.isAuthorized || !orgBranchId || !token) return;
        if (failedTokenRef.current === token) return;

        if (
            socketRef.current?.readyState === WebSocket.OPEN &&
            currentTokenRef.current === token
        )
            return;

        currentTokenRef.current = token;
        socketRef.current = new WebSocket(
            `wss://wisecity.systems/execute/client?AuthToken=${token}`,
        );

        socketRef.current.onopen = () => {
            console.log('WebSocket connected');
            failedTokenRef.current = null;
            socketRef.current?.send(
                JSON.stringify({
                    clientSyncId: generateClientSyncId(),
                    subscriptionEvents: [
                        {
                            clientEvent: 'PreSupportRequestCreateByCall',
                            orgBranchIds: [orgBranchId],
                            houseIds: [],
                        },
                    ],
                    method: 'WebsocketSystemObjects.SubscribeToClientEvents',
                }),
            );
        };

        socketRef.current.onmessage = (ev) => {
            try {
                const response = JSON.parse(ev.data);
                if (response['callerPhoneNumber']) {
                    telephonyStore.setWsCallerInfo(response);
                }
            } catch (err) {
                console.log(err);
            }
        };

        socketRef.current.onerror = (err) =>
            console.log('WebSocket error', err);

        socketRef.current.onclose = (event) => {
            console.log('WebSocket disconnected:', event.reason);

            if (event.code === 4401) {
                failedTokenRef.current = token;
                console.log('WebSocket closed due to authorization error');
            } else if (authStore.isAuthorized) {
                scheduleReconnect();
            }
        };
    };

    const scheduleReconnect = () => {
        if (reconnectTimeoutRef.current) return;
        reconnectTimeoutRef.current = setTimeout(() => {
            connectWebSocket();
            reconnectTimeoutRef.current = null;
        }, 5000);
    };

    useEffect(() => {
        connectWebSocket();

        return () => {
            closeWebSocket();
            if (reconnectTimeoutRef.current)
                clearTimeout(reconnectTimeoutRef.current);
        };
    }, [orgBranchId, token, authStore.isAuthorized]);

    useEffect(() => {
        if (!authStore.isAuthorized) closeWebSocket();
    }, [authStore.isAuthorized]);

    return <IncomingCallSnackbar />;
};

export default observer(Websockets);
